
body {
  font-family: "Poppins", sans-serif;
}
.slds-box {
  border: none !important;
}
.slds-button {
  background-color: white !important;
  border: 2px solid gainsboro !important;
  padding: 5px 50px !important;
  color: gray !important;
  font-size: large;
}

.slds-input {
  
  color: black !important;
  
}

.slds-context-bar {
  /* height: 4.5rem !important; */
  border-bottom: 0 !important;
}
.slds-context-bar__primary {
  gap: 52px;
}
.slds-text-color_default {
  color: grey;
}

.slds-text-color_default {
  color: grey !important;
}
.slds-m-top_medium {
  margin-top: 20px !important;
}
.slds-tabs_default {
  gap: 10px !important;
}
.slds-context-bar {
  display: block !important;
}
.slds-tabs_default__nav {
  border: none !important;
}
.slds-show {
  margin-top: 20px !important;
}
.slds-select_container .slds-select {
  padding-left: 4.6rem !important;
  padding-right: 4.6rem !important;
}
.slds-form_inline .slds-form-element{
  margin-right:0.2rem !important
}